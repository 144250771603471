import React from 'react';
import './Footer.scss';
import icon from '../../images/icons/icons.svg';
function Footer() {
  return (
    <footer>
      <p>
        <a href="https://damnitrahul.com/">
          <svg>
            <use href={`${icon}#arrow-left`} />
          </svg>
          Go back to damnitrahul.com
        </a>
      </p>
    </footer>
  );
}

export default Footer;
