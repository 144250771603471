import React from 'react';
import './NavBar.scss';
import logo from '../../images/work-logo.png';

function NavBar() {
  return (
    <nav id="nav">
      <div className="container">
        <div className="logo">
          <img src={logo} alt="logo" />
        </div>
        <div className="nav-btn">
          <a href="mailto:damnitrahul@gmail.com" className="hero-cta">
            Contact <span>Me</span>
          </a>
        </div>
      </div>
    </nav>
  );
}

export default NavBar;
