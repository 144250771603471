import React, { useContext } from "react";
import FeaturedProject from "./FeaturedProject";
import "./Featured.scss";
import { DataContext } from "../../context/DataContext";

function Featured() {
  const data = useContext(DataContext);
  const projects = data.filter((project) => project.featured);
  return (
    <section className="featured-area container">
      <h1 className="title">
        <span>Featured Projects</span>
      </h1>
      {projects.map((project, i) => (
        <FeaturedProject
          project={project}
          key={project.name}
          rtl={i % 2 !== 0}
          animationDelay={i * 0.25}
        />
      ))}
    </section>
  );
}

export default Featured;
