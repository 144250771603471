import React, { useContext } from 'react';
import Project from './Project';
import { DataContext } from '../../context/DataContext';
import './ProjectArea.scss';
function ProjectArea() {
  const data = useContext(DataContext);
  const projects = data.filter(project => !project.featured);
  return (
    <section className="project-area container">
      <h1 className="title">
        <span>Other Noteworthy Projects</span>
      </h1>
      <div className="project-grid">
        {projects.map((project, i) => (
          <Project
            project={project}
            key={project.id}
            animationDelay={i * 0.25}
          />
        ))}
      </div>
    </section>
  );
}

export default ProjectArea;
