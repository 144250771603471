// Scalewolf
import thumb1 from "../images/work/scalewolf.png";
import ss1 from "../images/work/scalewolf-ss.png";
//Colorify
import thumb2 from "../images/work/colorify.png";
import ss2 from "../images/work/colorify-ss.png";
//Tableskit
import thumb3 from "../images/work/tableskit.png";
import ss3 from "../images/work/tableskit-ss.png";
//Billy
import thumb4 from "../images/work/billy.png";
import ss4 from "../images/work/billy-ss.png";
//BBR
import thumb5 from "../images/work/bbr.png";
import ss5 from "../images/work/bbr-ss.png";
//BGH
import thumb7 from "../images/work/bgh.png";
import ss7 from "../images/work/bgh-ss.png";
//GatsbyBlog
import thumb6 from "../images/work/flatmagazine.png";
import ss6 from "../images/work/flatmagazine-ss.png";
//Default
import default1 from "../images/work/default.png";
import dss1 from "../images/work/default-ss.png";

const projectData = [
  {
    title: "TablesKit",
    featured: true,
    desc: "Simple and easy-to-use template generator tool for affiliate bloggers and marketers. Create eye-catching tables and product showcase components instantly and increase conversion.",
    thumbnail: thumb3,
    screenshot: ss3,
    type: "Personal",
    tags: [
      "React",
      "Redux Toolkit",
      "TypeScript",
      "styled-components",
      "Design",
      "Template Generator",
    ],
    links: {
      live: "https://next.tableskit.com/",
    },
  },
  {
    title: "Best Buy RDP",
    featured: true,
    desc: "Marketing website for an RDP/VPS services company offering a wide variety of RDP Servers and related services. Minimal and playful design showcasing the wide range of products and services offered. ",
    thumbnail: thumb5,
    screenshot: ss5,
    type: "Freelance",
    tags: [
      "React.js",
      "Gatsby",
      "GraphQL",
      "styled-components",
      "Design",
      "Markdown",
    ],
    links: {
      live: "https://bestbuyrdp.netlify.app/",
    },
  },
  {
    title: "Billy",
    featured: true,
    desc: "A Serverless JAM Stack Invoicing Application made with React.js, Redux and Firebase and Styled using styled-components. Create & export GST ready invoice, keep track of payments and more.",
    thumbnail: thumb4,
    screenshot: ss4,
    type: "Personal",
    tags: [
      "Serverless",
      "React.js",
      "Redux",
      "Firebase",
      "Styled Component",
      "Chart.js",
    ],
    links: {
      git: "https://github.com/damnitrahul/billy",
      live: "https://billy.damnitrahul.com/",
    },
  },
  {
    title: "Blue Geek Hosting",
    featured: true,
    desc: "Marketing website for a hosting platform focused on providing fast and reliable hosting solutions at affordable pricing. Clean and professional design highlighting the aforementioned features.",
    thumbnail: thumb7,
    screenshot: ss7,
    type: "Freelance",
    tags: [
      "React.js",
      "Gatsby",
      "GraphQL",
      "styled-components",
      "Design",
      "Markdown",
    ],
    links: {
      live: "https://bgh.netlify.app/",
    },
  },
  {
    title: "Flat Magazine",
    featured: true,
    desc: "A flat design Gatsby-Starter to kick start your Gatsby blog. Powered by SanityCMS to manage the Content.",
    thumbnail: thumb6,
    screenshot: ss6,
    type: "Personal",
    tags: [
      "React.js",
      "Gatsby",
      "GraphQL",
      "styled-components",
      "Design",
      "SanityCMS",
    ],
    links: {
      git: "https://github.com/damnitrahul/gatsby-starter-flat-magazine",
      live: "https://flat-magazine.netlify.app/",
    },
  },
  {
    title: "ScaleWolf",
    featured: true,
    desc: "A minimally designed static site for a Shopify Agency that provides various Shopify related services. This project was completely designed and developed from scratch without any libraries using HTML CSS and Vanilla JS. ",
    thumbnail: thumb1,
    screenshot: ss1,
    type: "Freelance",
    tags: ["Design", "Static", "Adobe XD", "HTML", "SASS", "JavaScript"],
    links: { live: "http://scalewolf.com/" },
  },
  {
    title: "Hangman Game",
    featured: false,
    desc: "A classic game of Hangman, Built with React.js. Guess the letters of a random word given to you, save your Hangman and win, as simple as that.",
    thumbnail: default1,
    screenshot: dss1,
    type: "Personal",
    tags: ["React.js", "CSS", "HTML", "Game"],
    links: {
      git: "https://github.com/damnitrahul/react-hangman",
      live: "https://hangman.damnitrahul.com/",
    },
  },
  {
    title: "Colorify",
    featured: false,
    desc: "A color picker clone app similar to FLATUICOLORS. Create and save your custom color pallets or use one from the nine predefined pallets.",
    thumbnail: thumb2,
    screenshot: ss2,
    type: "Udemy",
    tags: ["HTML", "CSS", "React.js", "Udemy", "JavaScript"],
    links: {
      git: "https://github.com/damnitrahul/ReactColorPicker",
      live: "https://colorify.damnitrahul.com/",
    },
  },

  {
    title: "Forkify - Recipe Finder",
    featured: false,
    desc: "A Vanilla Javascript App built with the help of Food2Fork API. Find your favorite recipes and save the ingredients locally for your grocery list",
    thumbnail: default1,
    screenshot: dss1,
    type: "Udemy",
    tags: ["Vanilla JavaScript", "API", "WebPack", "Babel", "CSS"],
    links: {
      git: "https://github.com/damnitrahul/forkify",
      live: "https://forkify.damnitrahul.com/#47025",
    },
  },
  // {
  //   title: "Admit Int'l",
  //   featured: false,
  //   desc: "A static website for a college consultant agency helping students get into their dream college abroad.",
  //   thumbnail: default1,
  //   screenshot: dss1,
  //   type: "Freelance",
  //   tags: ["SCSS", "HTML", "JavaScript", "Design", "Static"],
  //   links: {
  //     live: "https://admitintl.netlify.com/",
  //   },
  // },
];

export default projectData;
