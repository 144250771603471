import React, { createContext } from 'react';
import data from '../data/projectsData';

export const DataContext = createContext();

function DataProvider(props) {
  return (
    <DataContext.Provider value={data}>{props.children}</DataContext.Provider>
  );
}

export default DataProvider;
