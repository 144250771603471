import React from "react";
import "./FeaturedProject.scss";
import icon from "../../images/icons/icons.svg";
function FeaturedProject(props) {
  const { title, desc, thumbnail, tags, type, links, screenshot } =
    props.project;
  return (
    <div
      className={
        props.rtl ? "featured wow fadeInUp rtl" : "featured wow fadeInUp"
      }
      data-wow-delay={props.animationDelay + "s"}
    >
      <div className="detail">
        <h2>{title}</h2>
        <p className="type">{type} Project</p>
        <p className="desc">
          {desc} <br />
          {links.git && (
            <a
              href={links.git}
              target="_blank"
              title="View Github Repo"
              rel="noopener noreferrer"
            >
              <span className="svg-icon">
                <svg>
                  <use href={`${icon}#github`} />
                </svg>
              </span>
            </a>
          )}{" "}
          {links.live && (
            <a
              href={links.live}
              target="_blank"
              title="View Project Live"
              rel="noopener noreferrer"
            >
              <span className="svg-icon">
                <svg>
                  <use href={`${icon}#external`} />
                </svg>
              </span>
            </a>
          )}{" "}
          {links.blog && (
            <a
              href={links.blog}
              target="_blank"
              title="View Project Blog"
              rel="noopener noreferrer"
            >
              <span className="svg-icon">
                <svg>
                  <use href={`${icon}#website`} />
                </svg>
              </span>
            </a>
          )}
        </p>
        <p className="tags">
          {tags.map((tag) => (
            <span key={tag}>{tag}</span>
          ))}
        </p>
      </div>
      <div
        className="thumbnail"
        data-src={thumbnail}
        style={{
          "--bg-image": `url(${thumbnail})`,
          "--bg-hover": `url(${screenshot})`,
        }}
      >
        <img src={thumbnail} alt="" />
      </div>
    </div>
  );
}

export default FeaturedProject;
