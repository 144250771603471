import React from "react";
import NavBar from "./components/navbar/NavBar";
import Featured from "./components/featured/Featured";
import ProjectArea from "./components/projects/ProjectArea";
import Footer from "./components/footer/Footer";

function App() {
  console.log(
    "%c 👋 Oh, Hi there, Web lover!",
    "color: #d74034; font-size:24px;"
  );
  console.log(
    `%c  ✨ Like what you see? We could work together!
    
  🐛 Found a bug? Please, let me know by e-mail, twitter, github issue...
    
  📬 Find me here: https://twitter.com/damnitrahul
    
  Let the debug begin!
  
  Bonus meme: https://i.redd.it/jd25yqv8xsf31.jpg
  `,
    "font-size:16px"
  );
  return (
    <div>
      <NavBar />
      <Featured />
      <ProjectArea />
      <Footer />
    </div>
  );
}

export default App;
