import React from 'react';
import './Project.scss';
import icon from '../../images/icons/icons.svg';
function Project(props) {
  const { title, desc, tags, type, links } = props.project;
  return (
    <div
      className="project wow fadeInUp"
      data-wow-delay={props.animationDelay + 's'}
    >
      <div className="title-icon">
        <div className="folder-icon">
          <svg>
            <use href={`${icon}#folder`} />
          </svg>
        </div>
        <div className="link-icon">
          {links.git && (
            <a
              href={links.git}
              target="_blank"
              title="View Github Repo"
              rel="noopener noreferrer"
            >
              <span className="svg-icon">
                <svg>
                  <use href={`${icon}#github`} />
                </svg>
              </span>
            </a>
          )}{' '}
          {links.live && (
            <a
              href={links.live}
              target="_blank"
              title="View Project Live"
              rel="noopener noreferrer"
            >
              <span className="svg-icon">
                <svg>
                  <use href={`${icon}#external`} />
                </svg>
              </span>
            </a>
          )}{' '}
          {links.blog && (
            <a
              href={links.blog}
              target="_blank"
              title="View Project Blog"
              rel="noopener noreferrer"
            >
              <span className="svg-icon">
                <svg>
                  <use href={`${icon}#website`} />
                </svg>
              </span>
            </a>
          )}
        </div>
      </div>
      <h2>{title}</h2>
      <p className="type">{type} Project</p>
      <p className="desc">{desc} </p>
      <p className="tags">
        {tags.map((tag) => (
          <span key={tag}>{tag}</span>
        ))}
      </p>
    </div>
  );
}

export default Project;
